import React from 'react';
import pt from 'prop-types';
import { Button, FieldOfStudySelector } from '../components';

export default function HelpForm2(props) {
  return (
    <section className="form">
      <label htmlFor="first_name">
        Voornaam
        <input
          type="text"
          name="first_name"
          id="first_name"
          placeholder="John"
          value={props.first_name}
          onChange={props.firstNameChanged}
        />
      </label>
      <label htmlFor="last_name">
        Naam
        <input
          type="text"
          name="last_name"
          id="last_name"
          placeholder="Doe"
          value={props.last_name}
          onChange={props.lastNameChanged}
        />
      </label>
      <label htmlFor="anon">
        <input
          type="checkbox"
          name="anon"
          id="anon"
          value={props.is_anonymous}
          onChange={props.isAnonymousChanged}
        />
        Ik wil mijn hulpvraag anoniem posten. Enkel het monitoraat mag mijn naam
        weten.
      </label>
      <FieldOfStudySelector
        field_of_study={props.field_of_study}
        fieldOfStudyChanged={props.fieldOfStudyChanged}
      />

      <label htmlFor="email">
        E-mailadres
        <br />
        <input
          type="text"
          name="email"
          id="email"
          className="email"
          placeholder="john.doe"
          value={props.email}
          onChange={props.emailChanged}
        />
        <span>@ugent.be</span>
      </label>
      <label htmlFor="gdpr">
        <input
          type="checkbox"
          name="gdpr"
          id="gdpr"
          value={props.gdpr_checked}
          onChange={props.gdprCheckedChanged}
        />
        Door een hulpvraag te versturen ga ik akkoord met het{' '}
        <a href="/privacy" target="_BLANK" rel="noopener noreferrer">
          privacybeleid
        </a>
        .
      </label>
      <br />

      <Button label="Versturen" clicked={props.buttonClicked} />
    </section>
  );
}

HelpForm2.propTypes = {
  firstNameChanged: pt.func.isRequired,
  first_name: pt.string.isRequired,
  lastNameChanged: pt.func.isRequired,
  last_name: pt.string.isRequired,
  isAnonymousChanged: pt.func.isRequired,
  is_anonymous: pt.bool.isRequired,
  fieldOfStudyChanged: pt.func.isRequired,
  field_of_study: pt.string.isRequired,
  emailChanged: pt.func.isRequired,
  email: pt.string.isRequired,
  gdprCheckedChanged: pt.func.isRequired,
  gdpr_checked: pt.bool.isRequired,
  buttonClicked: pt.func.isRequired,
};
