import React from 'react';
import { Button, FieldOfStudySelector } from '../components';
import styles from './ReplyForm.module.scss';

export default function ReplyForm(props) {
  return (
    <section className={`form ${styles.container}`}>
      <h4>Help {props.request.first_name}</h4>

      <label htmlFor="email">E-mailadres</label>

      <input
        type="text"
        className="email"
        name="email"
        id="email"
        value={props.email}
        onChange={props.emailChanged}
      />
      <span>@ugent.be</span>

      <label htmlFor="anon">
        <input
          type="checkbox"
          value={props.is_anonymous}
          onChange={props.isAnonymousChanged}
          name="anon"
          id="anon"
        />
        Ik wil mijn hulp anoniem aanbieden. Enkel het monitoriaat mag mijn naam
        weten.
      </label>

      <FieldOfStudySelector
        field_of_study={props.field_of_study}
        fieldOfStudyChanged={props.fieldOfStudyChanged}
      />

      <label htmlFor="message">
        Schrijf een boodschap aan {props.request.first_name}
      </label>
      <textarea
        value={props.message}
        onChange={props.messageChanged}
        maxLength={450}
        name="message"
        id="message"
      />

      <label htmlFor="file">Stuur meteen documenten door:</label>
      <input
        type="file"
        name="files"
        onChange={props.fileChanged}
        multiple={true}
      />

      <label htmlFor="gdpr">
        <input
          type="checkbox"
          value={props.gdpr_checked}
          onChange={props.gdprCheckedChanged}
          name="gdpr"
          id="gdpr"
        />
        Door een hulpvraag te versturen ga ik akkoord met het{' '}
        <a href="/privacy" target="_BLANK" rel="noopener noreferrer">
          privacybeleid
        </a>
        .
      </label>

      <Button
        label="Versturen"
        clicked={props.buttonClicked}
        styles={[styles.button]}
      />
    </section>
  );
}
