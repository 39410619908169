import React from 'react';
import { Screen, Breadcrumb } from '../components';
import styles from './RequestValidatedScreen.module.scss';
import { NavLink } from 'react-router-dom';

export default class RequestValidatedScreen extends React.Component {

  render() {
    const query = new URLSearchParams(this.props.location.search);
    const validated = query.get('validated');
    if(validated === "valid" || validated === "certificate_ok"){
      return (
        <Screen>
          
          <Breadcrumb title="Aanvraag gevalideerd" />
          <section className={styles.container}>
            <h1>Aanvraag succesvol gevalideerd.</h1>
            <p>
              Deze aanvraag werd succesvol gevalideerd. <span role="img" aria-label="smiley">😀</span>
              <br />
              <NavLink to="/admin">&raquo; Naar de managementconsole</NavLink>
            </p>
          </section>
        </Screen>
      );
    } else {
      return (
        <Screen>         
          <Breadcrumb title="Aanvraag afgekeurd" />
          <section className={styles.container}>
            <h1>Aanvraag afgekeurd.</h1>
            <p>
              Deze aanvraag werd afgekeurd.
              <br />
              <NavLink to="/admin">&raquo; Naar de managementconsole</NavLink>
            </p>
          </section>
        </Screen>
      );
    }
  }
}
