import React from 'react';
import {
  Button,
  EditRequestDialog,
  ScreenAdmin,
  ValidatedBadge,
  Spinner,
} from '../components';
import { callAPI } from '../helpers';
import styles from './AdminScreen.module.scss';

export default class AdminScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      requests: [],
      users: [],
      link: null,

      activeTab: 1,
      showingDetail: null,
      sortFilter: 'verified',
      sortDirection: 'asc',
    };

    this.getRequests = this.getRequests.bind(this);
    this.getSummary = this.getSummary.bind(this);
    this.validateRequest = this.validateRequest.bind(this);
  }

  componentDidMount() {
    this.getRequests();
    this.getSummary();
    this.sortRequests('verified');
  }

  sortRequests(filter) {
    this.setState({ sortDirection: 'asc' });
    if (
      this.state.sortFilter === filter &&
      this.state.sortDirection === 'asc'
    ) {
      this.setState({ sortDirection: 'desc' });
    } else {
      this.setState({ sortFilter: filter });
    }

    switch (filter) {
      case 'name':
        this.state.requests.sort((a, b) => {
          if (this.state.sortDirection === 'asc') {
            return a.first_name.toLowerCase() > b.first_name.toLowerCase()
              ? 1
              : -1;
          }
          return a.first_name.toLowerCase() < b.first_name.toLowerCase()
            ? 1
            : -1;
        });
        break;
      case 'verified':
        this.state.requests.sort((a, b) => {
          if (this.state.sortDirection === 'asc') {
            if (a.validated < b.validated) return -1;
            if (a.validated > b.validated) return 1;
            return 0;
          } else {
            if (b.validated < a.validated) return -1;
            if (b.validated > a.validated) return 1;
            return 0;
          }
        });
        break;
      case 'type':
        this.state.requests.sort((a, b) => {
          if (this.state.sortDirection === 'asc') {
            return a.type.toLowerCase() > b.type.toLowerCase() ? 1 : -1;
          }
          return a.type.toLowerCase() < b.type.toLowerCase() ? 1 : -1;
        });
        break;
      case 'date':
        this.state.requests.sort((a, b) => {
          var aDate = new Date(a.createdAt);
          var bDate = new Date(b.createdAt);
          if (this.state.sortDirection === 'asc') {
            return aDate > bDate ? -1 : 1;
          }
          return aDate < bDate ? -1 : 1;
        });
        break;
      case 'active':
        this.state.requests.sort((a, b) => {
          if (this.state.sortDirection === 'asc') {
            return a.online < b.online ? 1 : -1;
          }
          return a.online > b.online ? 1 : -1;
        });
        break;
      case 'reply':
        this.state.requests.sort((a, b) => {
          if (this.state.sortDirection === 'asc') {
            return a.replies.length < b.replies.length ? 1 : -1;
          }
          return a.replies.length > b.replies.length ? 1 : -1;
        });
        break;
      default:
        break;
    }
  }

  async getRequests() {
    const token = localStorage.getItem('token');
    this.setState({ loading: true });
    try {
      const json = await callAPI('/admin/replies', 'POST', { token });
      this.setState({ error: null, loading: false, requests: json.requests });
    } catch (err) {
      this.setState({ loading: false, error: err });
    }
  }

  async getSummary() {
    const token = localStorage.getItem('token');
    try {
      const json = await callAPI('/admin/summary', 'POST', { token });
      this.setState({ error: null, users: json.users, link: json.link });
    } catch (err) {
      this.setState({ loading: false, error: err });
    }
  }

  async validateRequest(id, validated) {
    const token = localStorage.getItem('token');
    this.setState({ loading: true });
    try {
      const json = await fetch(
        `${global.backendRoot}/admin/request/${id}/validate?validated=${validated}&token=${token}`
      );
      await this.getRequests();
      console.log(json.message);
    } catch (err) {
      this.setState({ loading: false, error: err });
    } finally {
      this.setState({ showingDetail: null }); // and close dialog
    }
  }

  async deleteAll() {
    if (window.confirm('Zeker dat u alle huidige aanvragen wil verwijderen?')) {
      const token = localStorage.getItem('token');
      this.setState({ loading: true });
      try {
        await callAPI(`/admin/request/delete`, 'DELETE', {
          token,
        });
        this.setState({ error: null, loading: false });
        this.getRequests();
      } catch (err) {
        this.setState({ loading: false, error: err });
      }
    }
  }

  async deleteRequest(event, id) {
    event.stopPropagation();
    if (window.confirm('Zeker dat u deze aanvraag wil verwijderen?')) {
      const token = localStorage.getItem('token');
      this.setState({ loading: true });
      try {
        await callAPI(`/admin/request/${id}/delete`, 'DELETE', {
          token,
        });
        this.setState({ error: null, loading: false });
        this.getRequests();
      } catch (err) {
        this.setState({ loading: false, error: err });
      }
    }
  }

  addZero(n) {
    if (n < 10) return '0' + n;
    else return n;
  }

  formatDate(date) {
    const d = new Date(date);
    return (
      this.addZero(d.getDate()) +
      '/' +
      this.addZero(Number(d.getMonth()) + 1) +
      '/' +
      d.getFullYear()
    );
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    } else if (this.state.error) {
      return <p>Something went wrong: {this.state.error + ''}</p>;
    }

    return (
      <ScreenAdmin>
        <div className={styles.tabbar}>
          <p
            className={this.state.activeTab === 1 ? styles.active : null}
            onClick={() => this.setState({ activeTab: 1 })}
          >
            Hulpvragen
          </p>
          <p
            className={this.state.activeTab === 2 ? styles.active : null}
            onClick={() => this.setState({ activeTab: 2 })}
          >
            Exporteren
          </p>
        </div>

        {this.state.showingDetail && (
          <EditRequestDialog
            visible={true}
            request={this.state.showingDetail}
            validateRequest={(id, validated) =>
              this.validateRequest(id, validated)
            }
            clickedOutside={() => this.setState({ showingDetail: null })}
          />
        )}
        {this.state.activeTab === 1 && (
          <div>
            <div className={styles.actions}>
              <Button
                styles={[styles.nomargin]}
                label="Verwijder alles 🗑️"
                clicked={() => this.deleteAll()}
              />
            </div>

            <table>
              <thead>
                <tr>
                  <td
                    className={`${styles.sortable} ${
                      this.state.sortDirection === 'asc' &&
                      this.state.sortFilter === 'name'
                        ? styles.down
                        : ''
                    }`}
                    onClick={() => {
                      this.sortRequests('name');
                    }}
                  >
                    Naam student
                  </td>
                  <td
                    className={`${styles.sortable} ${
                      this.state.sortDirection === 'asc' &&
                      this.state.sortFilter === 'active'
                        ? styles.down
                        : ''
                    }`}
                    onClick={() => {
                      this.sortRequests('active');
                    }}
                  >
                    Online
                  </td>
                  <td
                    className={`${styles.sortable} ${
                      this.state.sortDirection === 'asc' &&
                      this.state.sortFilter === 'verified'
                        ? styles.down
                        : ''
                    }`}
                    onClick={() => {
                      this.sortRequests('verified');
                    }}
                  >
                    Geverifieerd
                  </td>
                  <td
                    className={`${styles.sortable} ${
                      this.state.sortDirection === 'asc' &&
                      this.state.sortFilter === 'type'
                        ? styles.down
                        : ''
                    }`}
                    onClick={() => {
                      this.sortRequests('type');
                    }}
                  >
                    Type aanvraag
                  </td>
                  <td
                    className={`${styles.sortable} ${
                      this.state.sortDirection === 'asc' &&
                      this.state.sortFilter === 'date'
                        ? styles.down
                        : ''
                    }`}
                    onClick={() => {
                      this.sortRequests('date');
                    }}
                  >
                    Datum aanvraag
                  </td>
                  <td
                    className={`${styles.sortable} ${
                      this.state.sortDirection === 'asc' &&
                      this.state.sortFilter === 'reply'
                        ? styles.down
                        : ''
                    }`}
                    onClick={() => {
                      this.sortRequests('reply');
                    }}
                  >
                    Reacties
                  </td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {this.state.requests.length ? (
                  this.state.requests.map((r) => (
                    <tr
                      key={r.id}
                      onClick={() => this.setState({ showingDetail: r })}
                    >
                      <td>
                        {r.first_name} {r.last_name}
                      </td>
                      <td>{r.online ? 'Ja' : 'Neen'}</td>
                      <td>
                        {(r.validated === 'certificate_ok' ||
                          r.validated === 'valid') && (
                          <ValidatedBadge validated={r.validated} />
                        )}
                        {r.validated === 'no' && (
                          <Button
                            styles={[styles.nomargin]}
                            label="Beantwoorden"
                            clicked={() => this.setState({ showingDetail: r })}
                          />
                        )}
                        {r.validated === 'invalid' && <span>Afgekeurd</span>}
                      </td>
                      <td>{r.type}</td>

                      <td>{this.formatDate(r.createdAt)}</td>
                      <td>{r.replies.length}</td>
                      <td
                        onClick={(e) => {
                          this.deleteRequest(e, r.id);
                        }}
                      >
                        <span role="img" aria-label="bin">🗑️</span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p>Geen hulpaanvragen gevonden.</p>
                )}
              </tbody>
            </table>
          </div>
        )}
        {this.state.activeTab === 2 && (
          <div className={styles.exporttab}>
            <a
              href={`${global.backendRoot}/${this.state.link}`}
              target="_BLANK"
              rel="noopener noreferrer"
            >
              Download Excel <span role="img" aria-label="arrow-down">🔽</span>
            </a>
            <br />
            <br />
            {this.state.users.map((u) => {
              return (
                <div key={u.id}>
                  <p>{u.email}</p>
                  <p>{u.helpedCount} keer geholpen</p>
                  <p>Richting: {u.field_of_study}</p>
                  <hr />
                </div>
              );
            })}
          </div>
        )}
      </ScreenAdmin>
    );
  }
}
