import React from 'react';
import pt from 'prop-types';
import styles from './ValidatedBadge.module.scss';

export default function VerifiedBadge(props) {
  return (
    <span>
      <span className={`${styles.verification} ${styles[props.validated]}`}>
        Geverifieerd
        {props.validated === 'certificate_ok' && (
          <span className={styles.tooltip}>
            De opgegeven reden werd door het monitoraat geverifieerd op basis
            van ingestuurde documenten of attesten.
          </span>
        )}
      </span>
    </span>
  );
}

VerifiedBadge.propTypes = {
  validated: pt.oneOf(['valid', 'invalid', 'certificate_ok', 'no'])
};
