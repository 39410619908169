import React from 'react';
import { Link } from 'react-router-dom';

export default function HelpForm3() {
  return (
    <section className="intro">
      <h1 className="center">Wat nu?</h1>
      <p>
        Een medewerker van het monitoraat controleert je aanvraag en verifieert
        de eventueel bijgevoegde documenten.&nbsp;
        <strong>Binnen de 3 werkdagen zal je vraag behandeld worden.</strong>
      </p>
      <p>
        Heb je nog vragen of wens je liever off-line hulp,{' '}
        <Link to="/contact">neem dan contact op &gt;</Link>
      </p>
    </section>
  );
}
