import React from 'react';
import {
  Screen,
  Breadcrumb,
  HelpForm1,
  HelpForm2,
  HelpForm3,
} from '../components';
import { LoadingScreen } from '.';
import { uploadFiles } from '../helpers';
import styles from './HelpScreen.module.scss';

export default class HelpScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      activeStep: 1,
      error: null,
      message: null,

      type: 'ondersteuning/begeleiding',
      keywords: '',
      reason: 'Andere',
      reason_motivation: '',
      files: null,

      first_name: '',
      last_name: '',
      is_anonymous: false,
      field_of_study: 'n.v.t.',
      email: '',
      gdpr_checked: false,
    };

    this.scrollTop = this.scrollTop.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
    // this.uploadFile = this.uploadFile.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
  }

  // Back button should go back 1 step
  // componentDidMount() {
  //   window.onpopstate = function(e) {
  //     e.preventDefault();
  //     console.log('ye');

  //     if (this.state.activeStep > 1)
  //       this.setState({ activeStep: this.state.activeStep - 1 });
  //   };
  // }

  validateForm() {
    if (!this.state.first_name || this.state.first_name === '') {
      this.setState({ error: 'Gelieve je voornaam in te vullen' });
      return false;
    }
    if (!this.state.email || this.state.email === '') {
      this.setState({ error: 'Gelieve je e-mailadres in te vullen' });
      return false;
    }
    if (!this.state.gdpr_checked || this.state.gdpr_checked === false) {
      this.setState({
        error:
          'Om een aanvraag te versturen dien je akkoord te gaan met onze privacyverklaring',
      });
      return false;
    }
    return true;
  }

  async submitRequest(upload_short_id) {
    return await fetch(`${global.backendRoot}/request`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: this.state.type,
        reason: this.state.reason,
        keywords: this.state.keywords || null,
        reason_motivation: this.state.reason_motivation,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        is_anonymous: this.state.is_anonymous,
        field_of_study: this.state.field_of_study,
        email: this.state.email,

        upload_short_id,
      }),
    });
  }

  // Todo: Handle errors better
  async submitForm() {
    if (!this.validateForm()) return;
    this.setState({ loading: true, error: null });

    try {
      // Upload first
      let upload_short_ids = null;
      if (this.state.files) {
        upload_short_ids = await uploadFiles(this);
        if (!upload_short_ids) return; // Upload failed
      }

      console.log('shortids');
      console.log(upload_short_ids);

      // Submit rest of form with upload_id from upload
      const res = await this.submitRequest(upload_short_ids);
      const json = await res.json();

      // Yay!
      if (res.status === 200) {
        this.scrollTop();
        return this.setState({
          activeStep: 3,
          message: json.message,
          loading: false,
        });
      } else {
        // Ney :(
        console.error(json.message);
        return this.setState({ error: json.message, loading: false });
      }
    } catch (err) {
      console.error(err);
      return this.setState({ error: err, loading: false });
    }
  }

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <Screen>
        <Breadcrumb title="Je zoekt hulp?" />
        {this.state.activeStep === 1 && (
          <section className="intro">
            <h1>Je zoekt hulp?</h1>
            <p>
              Omschrijf via onderstaand formulier waar je naar op zoek bent.
            </p>
            <p>
              Staat je type hulpvraag niet in de lijst, kies dan
              &quot;andere&quot;.
            </p>
            <p>
              <strong>
                Je hulpvraag zal door een medewerker van het monitoraat
                gecontroleerd worden en binnen de 3 werkdagen online worden
                geplaatst.
              </strong>
            </p>
            <br />
          </section>
        )}

        {this.state.activeStep === 2 && (
          <h2 className={`intro ${styles.almostThere}`}>
            Je bent er bijna, we hebben nog enkele gegevens nodig
          </h2>
        )}

        <section>
          <ul className="progress">
            <li>
              <span
                className={this.state.activeStep === 1 ? 'active' : null}
                onClick={() => {
                  if (this.state.activeStep > 1) {
                    this.scrollTop();
                    this.setState({ activeStep: 1 });
                  }
                }}
              >
                1
              </span>
              <p>Omschrijving</p>
            </li>
            <li>
              <span
                className={this.state.activeStep === 2 ? 'active' : null}
                onClick={() => {
                  if (this.state.activeStep > 2) {
                    this.scrollTop();
                    this.setState({ activeStep: 2 });
                  }
                }}
              >
                2
              </span>
              <p>Uw gegevens</p>
            </li>
            <li>
              <span className={this.state.activeStep === 3 ? 'active' : null}>
                3
              </span>
              <p>Vestuurd</p>
            </li>
          </ul>

          {this.state.message && <p className="center">{this.state.message}</p>}

          {this.renderActiveStep()}

          {/* Todo: Error in helpform above button? */}
          {this.state.error && (
            <span className={styles.error}>{this.state.error + ''}</span>
          )}
        </section>
      </Screen>
    );
  }

  renderActiveStep() {
    if (this.state.activeStep === 1) {
      return (
        <HelpForm1
          type={this.state.type}
          typeChanged={(e) => this.setState({ type: e.target.value })}
          reason={this.state.reason}
          reasonChanged={(e) => this.setState({ reason: e.target.value })}
          keywords={this.state.keywords}
          keywordsChanged={(e) => this.setState({ keywords: e.target.value })}
          reasonMotivation={this.state.reason_motivation}
          reasonMotivationChanged={(e) =>
            this.setState({ reason_motivation: e.target.value })
          }
          files={this.state.files}
          fileChanged={(e) => {
            this.setState({ files: e.target.files });
          }}
          buttonClicked={() => {
            this.scrollTop();
            this.setState({ activeStep: 2 });
          }}
        />
      );
    } else if (this.state.activeStep === 2) {
      return (
        <HelpForm2
          first_name={this.state.first_name}
          firstNameChanged={(e) =>
            this.setState({ first_name: e.target.value })
          }
          last_name={this.state.last_name}
          lastNameChanged={(e) => this.setState({ last_name: e.target.value })}
          is_anonymous={this.state.is_anonymous}
          isAnonymousChanged={(e) =>
            this.setState({ is_anonymous: e.target.checked })
          }
          field_of_study={this.state.field_of_study}
          fieldOfStudyChanged={(e) =>
            this.setState({ field_of_study: e.target.value })
          }
          email={this.state.email}
          emailChanged={(e) => this.setState({ email: e.target.value })}
          gdpr_checked={this.state.gdpr_checked}
          gdprCheckedChanged={(e) =>
            this.setState({ gdpr_checked: e.target.checked })
          }
          buttonClicked={this.submitForm}
        />
      );
    } else if (this.state.activeStep === 3) {
      return <HelpForm3 />;
    } else return null;
  }

  scrollTop() {
    window.scrollTo(0, 0);

    // would be nice but doesn't work with height of the screen changing and the animation thing
    // window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }
}
