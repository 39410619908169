import React from 'react';
import { Screen, Breadcrumb } from '../components';
import styles from './ContactScreen.module.scss';

export default class HomeScreen extends React.Component {
  render() {
    return (
      <Screen>
        <Breadcrumb title="Contact" />
        <section className={`intro ${styles.container}`}>
          <h1>Contact</h1>
          <p>
            Weet je niet waar je terecht kan met een vraag?
            <br />
            <a href="mailto:plusstudent@ugent.be">
              &raquo; plusstudent@ugent.be
            </a>
          </p>
        </section>
      </Screen>
    );
  }
}
