import React from 'react';
import styles from './Filter.module.scss';

export default class Filter extends React.Component {
  render() {
    return (
        <div className={styles.filter}>
            <label htmlFor={this.props.data.field_of_study.replaceAll(' ', '')}>
                {this.props.data.field_of_study + '(' + this.props.data.filter_count + ')'}
            </label>
            <input type="checkbox" id={this.props.data.field_of_study.replaceAll(' ', '')} checked={this.props.data.checked} onChange={this.props.filterClicked} readOnly/>
        </div>
    );
  }
}
