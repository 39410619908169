import React from 'react';
import styles from './Footer.module.scss';

export default class Footer extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <p>
          <a href="/privacy">Privacyverklaring</a>
        </p>
        <p>
          Een project van{' '}
          <a
            href="https://www.ugent.be/en"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            UGent
          </a>{' '}
          in co-creatie met{' '}
          <a
            href="https://www.odisee.be"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            Odisee
          </a>
          .
        </p>
      </div>
    );
  }
}
