import React from 'react';
import { Button, Screen, Request, Filter } from '../components';
import styles from './HomeScreen.module.scss';


export default class HomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      filterUsed: false,
      requests: [],
      showFilters: false
    };
  }



  componentDidMount() {
    fetch(`${global.backendRoot}/filters`)
      .then(response => response.json())
      .then(data => {
        data.filters.forEach(function(f){
          f.checked = false;
        });
        this.setState({filters: data.filters})
      });
    fetch(`${global.backendRoot}/requests`)
      .then(response => response.json())
      .then(data => this.setState({ requests: data.requests }));
  }

  filterAdded(filter){
    filter.checked = !filter.checked;
    var querystring = '';
    var filter_used = false;
    this.state.filters.forEach(function(f){
      if(f.checked){
        querystring += '&field_of_study=' + encodeURIComponent(f.field_of_study);
        filter_used = true;
      }
    });
    fetch(`${global.backendRoot}/requests?` + querystring)
      .then(response => response.json())
      .then(data => this.setState({requests: data.requests, filterUsed: filter_used}));
  }

  clearFilter(){
    this.state.filters.forEach(function(f){
      f.checked = false;
    });
    fetch(`${global.backendRoot}/requests`)
      .then(response => response.json())
      .then(data => this.setState({requests: data.requests, filterUsed: false}));
  }

  render() {
    return (
      <Screen>
        <section className={styles.intro}>
          <div>
            <h1>
              Hallo daar!
              <br />
              Help jij je medestudenten?
            </h1>
            <p>
              De plusstudent is een digitaal platform waar hulpzoekende en
              -biedende studenten van de faculteit recht en criminologie elkaar
              kunnen vinden.
              {/* <Link to="/about">Lees meer over de plusstudent &gt;&gt;</Link> */}
            </p>
          </div>
          {/* <img src={hand} alt="Hand logo" /> */}
        </section>

        <section>
          <h1 className="center">Studenten zoeken je hulp</h1>
          <div className={styles.filter_wrapper}>
            <button className={styles.btn_filter} onClick={() => this.setState({showFilters: !this.state.showFilters})}>
              <i className={styles.icon_filter}></i>
              Filter
            </button>
            {this.state.filterUsed && <button className={styles.btn_clear_filters} onClick={() => this.clearFilter()}><i className={styles.icon_close}>x</i> verwijder filter</button>}
          </div>
          {this.state.showFilters && this.state.filters.map(f => {
            return(
              <Filter
                data={f}
                key={f.field_of_study.replaceAll(' ', '')}
                filterClicked={() => this.filterAdded(f)}
                />
            )
          })}
          
          
          {this.state.requests.map(r => {
            return (
              <Request
                data={r}
                key={r.id}
                articleClicked={() =>
                  this.props.history.push('/request', { r })
                }
              />
            );
          })}
          <Button
            label="Ik zoek ook hulp"
            icon="plus"
            clicked={() => this.props.history.push('/help')}
          />
        </section>

        <section className={styles.links}>
          <h1 className="center">Ken je deze links al?</h1>
          <p>
            Weet je niet waar je terecht kan met een vraag?
            <br />
            <a
              href="https://www.ugent.be/re/nl/voor-studenten/fdo/monitoraat/overzicht.htm"
              target="_BLANK"
              rel="noreferrer noopener"
            >
              &raquo; Monitoraat
            </a>
          </p>
          <p>
            Nood aan een babbel?
            <br />
            <a
              href="https://www.ugent.be/student/nl/studeren/studiebegeleiding/studentenpsychologen"
              target="_BLANK"
              rel="noreferrer noopener"
            >
              &raquo; Studentenpsychologen
            </a>
          </p>
          <p>
            Voel je je even niet wel in je vel? <br />
            <a
              href="https://www.ugent.be/student/nl/meer-dan-studeren/wel-in-je-vel"
              target="_BLANK"
              rel="noreferrer noopener"
            >
              &raquo; Wel in je vel
            </a>
          </p>
          <p>
            Werkstudent of topsporter?
            <br />
            <a
              href="https://www.ugent.be/student/nl/administratie/flexibel-studeren/bijzonder-statuut"
              target="_BLANK"
              rel="noreferrer noopener"
            >
              &raquo; Bijzonder statuut
            </a>
          </p>
          <p>
            Problemen met huisvesting? <br />
            <a
              href="https://www.ugent.be/student/nl/meer-dan-studeren/huisvesting"
              target="_BLANK"
              rel="noreferrer noopener"
            >
              &raquo; Huisvesting
            </a>
          </p>
          <p>
            Financiële problemen? <br />
            <a
              href="https://www.ugent.be/student/nl/administratie/sociale-dienst"
              target="_BLANK"
              rel="noopener noreferrer"
            >
              Sociale dienst
            </a>
          </p>
        </section>
      </Screen>
    );
  }
}
