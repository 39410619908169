import React from 'react';
import styles from './ErrorMessage.module.scss';

export default class ErrorMessage extends React.Component {
    render() {
        return (
            <div className={styles.error}>
                <h2>Oeps, er ging iets mis.</h2>
                <p>{this.props.message}</p>
            </div>
        );
    }
}