
async function uploadFile(that) {
  const data = new FormData();
  data.append('file', that.state.file);

  const res = await fetch(`${global.backendRoot}/upload`, {
    method: 'POST',
    body: data
  });
  const uploadJson = await res.json();

  if (!res.status === 200) {
    that.setState({ error: uploadJson.message });
    return null;
  }

  return uploadJson.data.shortid;
}

// Returns shortids of uploaded files in array
async function uploadFiles(that) {
  const data = new FormData();
  data.append('files', that.state.files);

  // Add all files to formdata
  for (let i = 0; i < that.state.files.length; i++) {
    data.append('files', that.state.files[i]);
  }

  const res = await fetch(`${global.backendRoot}/upload/multiple`, {
    method: 'POST',
    body: data
  });
  const uploadJson = await res.json();

  if (!res.status === 200) {
    that.setState({ error: uploadJson.message });
    return null;
  }

  return uploadJson.shortids;
}

async function callAPI(route, method, body) {
  const res = await fetch(global.backendRoot + route, {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });

  if (res.status !== 200) {
    const json = await res.json();
    throw Error(json.message);
  }

  return await res.json();
}

async function handleResponse(response){
  const {BadRequestError, ServerError, UnauthorizedError} = require('@ikdoeict/ikdoejs');
  if(response.ok)
    return await response.json();
  else{
    if(response.status === 400){
      let data = await response.json();
      let message = data.message;
      console.log("400 Error: " + message);
      throw new BadRequestError(message);
    }else if(response.status === 401){
      await response.json();
      console.log("401 Error: Unauthorized");
      throw new UnauthorizedError("Je hebt geen rechten om deze actie uit te voeren")
    }

    else{
      console.log("500 Error: Internal Server Error")
      throw new ServerError("Internal Server Error");
    }
  }
}

export { uploadFile, callAPI, uploadFiles, handleResponse };
