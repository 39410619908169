import React from 'react';
import Router from './router';
import './firebase';
import './config/globals';
import './scss/App.scss';

// Root component
function App() {
  return <Router />;
}

export default App;
