import React from 'react';

export default function FieldOfStudySelector(props) {
  return (
    <div>
      <label htmlFor="field_of_study">Studierichting & jaar</label>
      <select
        name="field_of_study"
        value={props.field_of_study}
        onChange={props.fieldOfStudyChanged}
      >
        <option value="n.v.t.">Niet van toepassing</option>
        <option value="1ste bach. criminologie">1ste bach. criminologie</option>
        <option value="2de bach. criminologie">2de bach. criminologie</option>
        <option value="3de bach. criminologie">3de bach. criminologie</option>
        <option value="Verkorte bach. criminologie">
          Verkorte bach. criminologie
        </option>
        <option value="Master criminologie">Master criminologie</option>
        <option value="1ste bach. rechten">1ste bach. rechten</option>
        <option value="2de bach. rechten">2de bach. rechten</option>
        <option value="3de bach. rechten">3de bach. rechten</option>
        <option value="Schakelprogramma">Schakelprogramma</option>
        <option value="Voorbereidingsprogramma">Voorbereidingsprogramma</option>
        <option value="Master Rechten">Master Rechten</option>
        <option value="Personeel">Personeel</option>
      </select>
    </div>
  );
}
