import React from 'react';
import { Screen, Breadcrumb } from '../components';

export default class HomeScreen extends React.Component {
  render() {
    return (
      <Screen>
        <Breadcrumb title="Over de app" />
        <section>
          <h1>Over de Plusstudent-app</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas
            maecenas pharetra convallis posuere morbi. Morbi quis commodo odio
            aenean sed. Sem integer vitae justo eget magna fermentum iaculis eu.
            Massa id neque aliquam vestibulum. Id faucibus nisl tincidunt eget
            nullam non. Diam sollicitudin tempor id eu nisl nunc mi. Dui id
            ornare arcu odio ut. Quisque non tellus orci ac auctor augue. Sed
            vulputate odio ut enim blandit volutpat maecenas volutpat blandit.
            test
          </p>
        </section>
      </Screen>
    );
  }
}
