import React from 'react';
import styles from './Screen.module.scss';
import { Header, Footer } from '.';

export default class Screen extends React.Component {
  render() {
    return (
      <div
        className={`${styles.container} ${[...(this.props.styles || '')]}`}
        {...this.props}
      >
        <Header />
        <div
          className={`${styles.content} ${[
            ...(this.props.contentStyles || '')
          ]}`}
        >
          {this.props.children}
        </div>
        <Footer />
      </div>
    );
  }
}
