// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyB-nU28xWS2cU23ervgWTqqgtcTzTE5VQ4',
  authDomain: 'plusstudent-384fa.firebaseapp.com',
  databaseURL: 'https://plusstudent-384fa.firebaseio.com',
  projectId: 'plusstudent-384fa',
  storageBucket: 'plusstudent-384fa.appspot.com',
  messagingSenderId: '427614728025',
  appId: '1:427614728025:web:5dd8de3adb053b5681a648',
  measurementId: 'G-3686CKM4Q1'
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
