import React from 'react';
import styles from './ScreenAdmin.module.scss';
import { Link } from 'react-router-dom';
import { Footer } from '.';

export default class Screen extends React.Component {
  render() {
    return (
      <div className={styles.container} {...this.props}>
        <div className={styles.title}>
          <Link to="/">Plusstudent - Managementconsole</Link>
        </div>
        <div className={styles.content}>{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}
