import React from 'react';
import pt from 'prop-types';
import { Button } from '../components';

export default function HelpForm1(props) {
  return (
    <section className="form">
      <label htmlFor="type">Welke vorm van hulp zoek je</label>
      <select name="type" onChange={props.typeChanged} value={props.type}>
        <option disabled>Ik zoek ...</option>
        <option value="ondersteuning/begeleiding">
          Ondersteuning of begeleiding
        </option>
        <option value="een mentor">Een mentor</option>
        <option value="een leuke babbel">Een leuke babbel</option>
        <option value="studiehulp">Studiehulp</option>
        <option value="notities">Notities</option>
        <option value="student(en)">Student(en)</option>
        <option value="andere">Andere</option>
      </select>

      <label htmlFor="reason">Wat is de reden van je hulpvraag?</label>
      <select name="reason" onChange={props.reasonChanged} value={props.reason}>
        <option value="Andere">Andere</option>
        <option value="Afwezigheid">Afwezigheid (geen ziekte)</option>
        <option value="Ziekte zonder attest">Ziekte zonder attest</option>
        <option value="Ziekte met attest">Ziekte met attest</option>
        <option value="(Tijdelijk) fysieke beperking">
          (Tijdelijk) fysieke beperking
        </option>
      </select>

      <label htmlFor="reason_motivation">
        Omschrijving van je vraag en/of reden
      </label>
      <textarea
        name="reason_motivation"
        id="reason_motivation"
        placeholder="Ik heb de les gemist omdat..."
        onChange={props.reasonMotivationChanged}
        value={props.reasonMotivation}
      />
      <p className="remark">
        <em>Een duidelijke omschrijving zorgt meestal voor meer reacties.</em>
      </p>

      <label htmlFor="keywords">
        Vat jouw hulpvraag samen in een aantal kernwoorden
      </label>
      <textarea
        name="keywords"
        id="keywords"
        placeholder="‘Statistiek’, ‘Infodag’, ‘Procesrecht’, ‘Studentenrestaurant’, …"
        onChange={props.keywordsChanged}
        value={props.keywords}
      />
      <p className="remark">
        <em>Schrijf na elk van je kernwoorden een komma</em>
      </p>

      <label htmlFor="files">Attesten of documenten</label>
      <p className="remark">
        Wens je je hulpvraag te attesteren (bv. overlijdensbericht,
        ziekteattest, …), voeg hieronder dan documenten toe.
        <br />
        De ingestuurde documenten zijn enkel zichtbaar voor de medewerkers van
        het monitoraat. Indien waarheidsgetrouw verkrijgt je hulpvraag de status
        ‘geverifieerd’.
        <br />
        <em>Een geverifieerde hulpvraag zorgt meestal voor meer reacties.</em>
      </p>
      <input
        name="files"
        type="file"
        multiple={true}
        // setting a value here (for persistence) fks it up
        onChange={props.fileChanged}
      />

      <Button label="Volgende" clicked={props.buttonClicked} />
    </section>
  );
}

HelpForm1.propTypes = {
  typeChanged: pt.func.isRequired,
  type: pt.string.isRequired,
  reasonChanged: pt.func.isRequired,
  reason: pt.string.isRequired,
  keywordsChanged: pt.func.isRequired,
  keywords: pt.string.isRequired,
  reasonMotivationChanged: pt.func.isRequired,
  reasonMotivation: pt.string.isRequired,
  fileChanged: pt.func.isRequired,
  buttonClicked: pt.func.isRequired,
};
