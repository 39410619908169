import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Breadcrumb.module.scss';

export default class Breadcrumb extends React.Component {
  render() {
    return (
      <div className={styles.breadcrumb}>
        <p>
          <Link to="/">Home</Link>
          <span> > </span>
          <strong>{this.props.title}</strong>
        </p>
      </div>
    );
  }
}
