import React from 'react';
import { Screen } from '../components';
import { LoadingScreen } from '.';
import { callAPI } from '../helpers';
import styles from './LoginScreen.module.scss';

export default class HomeScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = { password: '', loading: false, error: null };
    this.submitForm = this.submitForm.bind(this);
  }

  async componentDidMount() {
    const token = localStorage.getItem('token');

    try {
      const json = await callAPI('/admin/check', 'POST', { token });

      if (json.message === 'ok') {
        return this.props.history.push('/admin');
      } else {
        throw Error('No valid token');
      }
    } catch (err) {
      localStorage.removeItem('token');
    }
  }

  async submitForm() {
    this.setState({ error: null, loading: true });
    try {
      const json = await callAPI('/admin/login', 'POST', {
        email: 'admin@ugent.be',
        password: this.state.password,
      });

      localStorage.setItem('token', json.token);
      this.setState({ loading: false, error: null });
      this.props.history.push('/admin');
    } catch (error) {
      this.setState({ loading: false, error });
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingScreen />;
    }
    return (
      <Screen>
        <div className={styles.container}>
          <p>Gelieve het administratorwachtwoord in te geven:</p>
          <input
            autoFocus
            type={'password'}
            onChange={(e) => this.setState({ password: e.target.value })}
            onKeyUp={(e) => {
              if (e.key === 'Enter') this.submitForm();
            }}
            value={this.state.password}
          />
          <input type={'submit'} onClick={this.submitForm} value={'Log in'} />

          {this.state.error && (
            <p className={styles.error}>
              Er is iets misgegaan
              <br />
              {this.state.error + ''}
            </p>
          )}
        </div>
      </Screen>
    );
  }
}
