import React from 'react';
import { Screen, Breadcrumb } from '../components';
import styles from './SuccessScreen.module.scss';
import { NavLink } from 'react-router-dom';

export default class HomeScreen extends React.Component {
  render() {
    return (
      <Screen>
        <Breadcrumb title="Aanvraag verwijderd" />
        <section className={styles.container}>
          <h1>Je aanvraag is succesvol verwijderd!</h1>
          <p>
            Leuk dat je vraag is opgelost. <span role="img" aria-label="smiley">😀</span>
            <br />
            <NavLink to="/help">&raquo; Maak een nieuwe aanvraag</NavLink>
          </p>
        </section>
      </Screen>
    );
  }
}
