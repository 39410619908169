import React from 'react';
import { Screen, Request, ErrorMessage, Button } from '../components';
import { Redirect } from 'react-router';
import { LoadingScreen } from '../screens';
import {handleResponse} from '../helpers';
import {BadRequestError, ServerError, UnauthorizedError} from '@ikdoeict/ikdoejs';


export default class RequestScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      request: {},
      loading: true,
      isvalid: true,
      token : '',
      id: -1,
      askConfirmDelete: false,
      deleteSucceeded: false,
      errorMessage: ''
    };
  }

  componentDidMount(){
    const { match, location} = this.props;
    if(!match.params['id']){
      this.setState({isvalid: false});
    }
    let id = match.params['id'];
    let params = new URLSearchParams(location.search);
    let token = params.get('token');
    fetch(`${global.backendRoot}/request/${id}`)
      .then(handleResponse)
      .then(data => this.setState({ request: data.request , loading: false, token: token, id: id}))
      .catch((error) => {
        if(error instanceof BadRequestError){
          this.setState({errorMessage: error.message, loading: false})
        } else if (error instanceof ServerError) {
          this.setState({errorMessage: "Er is iets misgegaan op de server."});
        } else {
          this.setState({errorMessage: "Er is iets misgegaan."})
        }
      });
  }

  removeRequest(){
    fetch(`${global.backendRoot}/request/${this.state.id}?token=${this.state.token}`, {
      method: 'DELETE'
    })
      .then(handleResponse)
      .then(data => this.setState({ deleteSucceeded: true}))
      .catch((error) => {
        if(error instanceof BadRequestError){
          this.setState({errorMessage: error.message, loading: false});
        }else if(error instanceof UnauthorizedError){
          this.setState({errorMessage: error.message, loading: false});
        } else if (error instanceof ServerError) {
          this.setState({errorMessage: "Er is iets misgegaan op de server.", loading: false});
        } else {
          this.setState({errorMessage: "Er is iets misgegaan.", loading: false})
        }
      });
  }


  render() {
    if (this.state.loading) return <LoadingScreen />;
    if(!this.state.isvalid){
      return <Redirect to="/"/>
    }
    if(this.state.errorMessage){
      return(
        <Screen>
          <ErrorMessage 
            message={this.state.errorMessage}
          />
        </Screen>
      );
    }
    if(this.state.deleteSucceeded){
      return(
        <Screen>
          <p>Jouw hulpvraag is verwijderd. </p>
        </Screen>
      );
    }
    if(this.state.askConfirmDelete){
      return (
        <Screen>
          <p>Ben je zeker dat je deze hulpvraag wilt verwijderen?</p>
          <Button label="Bevestigen" clicked={() => this.removeRequest()}></Button>
        </Screen>
      )
    }

    if(this.state.token){
      return(
        <Screen>
          <Request
                  data={this.state.request}
                  key={this.state.request.id}
                  fromRequestScreen={true}
                  removeArticle = {() => this.setState({askConfirmDelete: true})}
                />

        </Screen>
        
      );
    }else{
      return(
        <Screen>
          <Request
                  data={this.state.request}
                  key={this.state.request.id}
                  fromRequestScreen={false}
                />
        </Screen>
      );
    }
  }

}
