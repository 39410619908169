import React from 'react';
import pt from 'prop-types';
import styles from './EditRequestDialog.module.scss';

export default class EditRequestDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: this.props.request.validated === 'certificate_ok',
    };
  }
  render() {
    const r = this.props.request;

    let formattedUploadId = r.upload_short_id;

    if (!Array.isArray(formattedUploadId)) {
      formattedUploadId = [formattedUploadId]; // There was only one (old system compatibility)
    }

    return (
      <div
        className={`${styles.maincontainer} 
          ${this.props.visible ? styles.visible : null}`}
        onClick={this.props.clickedOutside}
      >
        <div
          className={`${styles.container} ${
            this.props.visible ? styles.visible : null
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.topRow}>
            <h2>
              {r.first_name} {r.last_name} zoekt {r.type}
            </h2>
            <span
              className={styles.closeIcon}
              onClick={this.props.clickedOutside}
            >
              X
            </span>
          </div>
          <hr />
          <p>
            <span className={styles.label}>Reden:</span>
            {r.reason}
            <span className={styles.horiz}></span>
            <label>
              <input
                type="checkbox"
                checked={this.state.verified}
                onChange={(e) => this.setState({ verified: e.target.checked })}
              />{' '}
              Attest geverifieerd
            </label>

            <span className={styles.horiz}></span>

            <br />

            {r.upload_short_id && (
              <span className={styles.label}>Download attesten:</span>
            )}
            {r.upload_short_id &&
              formattedUploadId.map((shortid, i) => (
                <span key={shortid}>
                  <a
                    href={`${global.backendRoot}/upload/${shortid}`}
                    target="_BLANK"
                    rel="noopener noreferrer"
                  >
                    &nbsp;{i + 1}&nbsp;🔽
                  </a>
                  &nbsp;
                </span>
              ))}
          </p>
          <p>
            <span className={styles.label}>Online zichtbaar:</span>
            {r.online ? 'Ja' : 'Neen'}
          </p>
          <p>
            <span className={styles.label}>Kernwoorden:</span>
            {r.keywords ? r.keywords : '/'}
          </p>
          <p>
            <span className={styles.label}>Anoniem gepost:</span>
            {r.is_anonymous ? 'Ja' : 'Neen'}
          </p>
          <p>
            <span className={styles.label}>Studiejaar:</span>
            {r.field_of_study}
          </p>
          <p>
            <span className={styles.label}>E-mail:</span>
            {r.email}
          </p>
          {r.reason_motivation && (
            <p>
              <span className={styles.label}>Omschrijving:</span>
              <br />
              <span className={styles.quote}>{r.reason_motivation}</span>
            </p>
          )}

          <div className={styles.buttons}>
            {/* <button
              className={styles.cancel}
              onClick={this.props.clickedOutside}
            >
              Annuleren
            </button>{' '} */}
            <button
              className={styles.outline}
              onClick={() => this.props.validateRequest(r.id, 'invalid')}
            >
              Afkeuren
            </button>{' '}
            <button
              className={styles.blue}
              onClick={() =>
                this.props.validateRequest(
                  r.id,
                  this.state.verified ? 'certificate_ok' : 'valid'
                )
              }
            >
              Goedkeuren
            </button>
          </div>
          <h3>Reacties</h3>
          {r.replies.length === 0 && <p>Geen</p>}
          {r.replies.map((rep) => (
            <div key={rep.id} className={styles.replies}>
              <h4>{rep.email}</h4>
              <p>{rep.message}</p>
              <div className={styles.extraInfo}>
                {rep.field_of_study && (
                  <p>Studierichting: {rep.field_of_study}</p>
                )}
                {rep.upload_short_id && <span>Download bijlagen: </span>}
                {rep.upload_short_id &&
                  rep.upload_short_id.map((shortid, i) => (
                    <span key={shortid}>
                      <a
                        href={`${global.backendRoot}/upload/${shortid}`}
                        target="_BLANK"
                        rel="noopener noreferrer"
                      >
                        &nbsp;{i + 1}&nbsp;
                      </a>
                      &nbsp;
                    </span>
                  ))}
                {Boolean(rep.is_anonymous) === true && <p>Anoniem gepost</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

EditRequestDialog.propTypes = {
  request: pt.shape({
    first_name: pt.string,
    last_name: pt.string,
    email: pt.string,
    reason_motivation: pt.string,
    id: pt.number,
    type: pt.string,
    reason: pt.string,
    keywords: pt.string,
    validated: pt.string,
    online: pt.number,
    is_anonymous: pt.number,
    field_of_study: pt.string,
    upload_short_id: pt.string,
    createdAt: pt.string,
    updatedAt: pt.string,
    replies: pt.arrayOf(
      pt.shape({
        email: pt.string,
        message: pt.string,
        id: pt.number,
        is_anonymous: pt.number,
        field_of_study: pt.string,
        createdAt: pt.string,
        updatedAt: pt.string,
        requestId: pt.number,
      })
    ),
  }).isRequired,

  validateRequest: pt.func.isRequired,
  clickedOutside: pt.func.isRequired,
  visible: pt.bool.isRequired,
};
