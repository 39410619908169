import React from 'react';
import { Screen } from '../components';
import handje from '../assets/hand.png';
import styles from './ThanksScreen.module.scss';

export default class ThanksScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: [],
    };
  }

  componentDidMount() {}

  render() {
    const helpedCount = this.props.location.state.helpedCount;
    const message = this.props.location.state.message;
    const toonDieHandjes = [];

    for (let i = 0; i < 3; i++) {
      toonDieHandjes.push(
        <img src={handje} alt="" style={{ animationDelay: i / 4 + 's' }} />
      );
    }

    return (
      <Screen>
        <div className={styles.centeredContainer}>
          <h1 className={styles.primary}>Woaw, fantastisch bedankt!</h1>
          <section className={styles.handjesContainer}>
            {toonDieHandjes}
          </section>

          <p>
            <em>{message}</em>
          </p>

          <p className={styles.big}>
            Je hielp reeds{' '}
            <span className={styles.underline}>{helpedCount} keer</span> een
            medestudent.
          </p>

          <p className={styles.big}>Bedankt voor je hulp!</p>
        </div>

        {/* <p>
          Je hielp reeds {helpedCount} keer een medestudent.
          <br />
          {5 - helpedCount > 0
            ? 'Nog ' +
              (5 - helpedCount) +
              ' plusje' +
              (5 - helpedCount > 1 ? 's ' : ' ') + // meervoud of niet
              'te gaan en er wacht een leuke verrassing op jou!'
            : 'Er wacht jou een leuke verrassing!'}
        </p> */}
        <br />
        <p>
          Wil je nog iemand helpen?
          <br />
          <a href="/">&lt; Ga terug naar de homepagina</a>
        </p>
      </Screen>
    );
  }
}
