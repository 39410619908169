import React from 'react';
import { Screen } from '../components';
import styles from './PrivacyScreen.module.scss';

export default function LoadingScreen() {
  return (
    <Screen>
      <div className={styles.container}>
        <p>
          <strong>PRIVACYVERKLARING/GDPR</strong>
        </p>
        <p>
          Deze ‘Privacyverklaring’ regelt hoe jouw persoonsgegevens worden
          verwerkt door de universiteit Gent,&nbsp;publiekrechtelijke
          vennootschap met maatschappelijke zetel te Sint-Pietersnieuwstraat 25,
          9000 Gent en met ondernemingsnummer BE 0248.015.142&nbsp;(Hierna:
          UGent). Universiteit Gent is verantwoordelijk voor de verwerking van
          persoonsgegevens die via haar website&nbsp;
          <a href="http://www.plusstudent.be">www.plusstudent.be</a> verkregen
          worden.
        </p>
        <p>
          Deze Privacyverklaring beschrijft <strong>welke</strong>{' '}
          persoonsgegevens we van jou verzamelen wanneer je deze website
          bezoekt, <strong>waarom</strong> we dat doen, met wie wij ze{' '}
          <strong>delen</strong>, wat <strong>je rechten</strong> zijn en hoe je
          deze kan uitoefenen.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Welke persoonsgegevens verwerken we?</strong>
        </p>
        <p>
          UGent verwerkt de persoonsgegevens die je zelf aan ons verstrekt,
          bijvoorbeeld je ugent emailadres dat je invult in het formulier van
          een hulpvraag.
        </p>
        <p>
          Meer bepaald kan je via deze formulieren volgende persoonsgegevens aan
          ons verstrekken: naam, voornaam, ugent e-mailadres, studierichting en
          -jaar, eventueel geüploade attesten of documenten.
        </p>
        <p>
          <em>
            Sommige gegevens worden ook automatisch verzameld wanneer je onze
            website bezoekt, doordat wij gebruikmaken van cookies (kleine
            tekstbestandjes die worden opgeslagen in de browser van een
            gebruiker).
          </em>
        </p>
        <p>
          <em>
            Meer bepaald kunnen daarbij volgende persoonsgegevens worden
            verwerkt: je IP-adres, je browsertype, de door jou ingestelde taal,
            wanneer en hoe lang je onze website bezoekt,…
          </em>
        </p>
        <p>
          <em>
            Meer informatie over hoe we gebruikmaken van cookies, kan je vinden
            in onze Cookieverklaring.
          </em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <strong>Waarom verwerken wij jouw persoonsgegevens?</strong>
        </p>
        <p>
          De persoonsgegevens die je zelf aan ons verstrekt, verwerken wij voor
          de volgende doeleinden:
        </p>
        <p>
          Voor de praktische organisatie van het plusstudentenproject, namelijk
          het kunnen contact opnemen met de hulpvrager/hulpverlener.&nbsp;
        </p>
        <p>
          Om het mogelijk te maken controle te kunnen uitoefenen op de
          geplaatste verzoeken/de geboden hulp.
        </p>
        <p>
          Om je te kunnen contacteren i.v.m. je hulpvraag, meer specifiek om je
          te kunnen verder helpen of doorverwijzen naar de juist dienst. Of om
          je te kunnen contacteren als hulpverlener met het oog op een
          honorering.
        </p>
        <p>
          <em>
            De persoonsgegevens die wij automatisch van jou verzamelen wanneer
            je onze website bezoekt, worden verwerkt voor de volgende
            doeleinden:
          </em>
        </p>
        <p>
          <em>
            Om onze website te beheren en verbeteren, zodat we de inhoud ervan
            op jouw kunnen afstemmen op de noden van de huidige
            studentenpopulatie.
          </em>
        </p>
        <p>
          <em>
            Voor het uitvoeren van statistische analyses met betrekking tot jouw
            bezoek- en klikgedrag.
          </em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <strong>Aan wie geven wij jouw persoonsgegevens door?</strong>
        </p>
        <p>
          De gegevens die je bij het plaatsen van een hulpvraag/hulpaanbod
          schrijft, worden gecommuniceerd naar en verzameld door het Monitoraat
          van de faculteit recht en criminologie van de UGent.
        </p>
        <p>
          <em>
            De persoonsgegevens die wij automatisch van jou verzamelen, worden
            gedeeld met de vakgroep ICT van hogeschool Odisee, vzw met
            maatschappelijke zetel te 1000 Brussel, Warmoesberg 26, ingeschreven
            in het rechtspersonenregister onder ondernemingsnummer 0408.429.584
            (Hierna: ‘Odisee’).
          </em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <strong>
            Welke rechten heb je met betrekking tot je persoonsgegevens?
          </strong>
        </p>
        <p>
          Je hebt verschillende rechten met betrekking tot je persoonsgegevens.
          Meer specifiek heb je het recht om:
        </p>
        <p>
          ;ons te vragen inzage te krijgen in de persoonsgegevens die we van jou
          verwerken en een kopie te vragen van de persoonsgegevens die we van
          jou hebben
        </p>
        <p>;ons te vragen je persoonsgegevens te updaten of te corrigeren</p>
        <p>;ons te vragen om je persoonsgegevens te wissen</p>
        <p>
          Indien je graag een van deze rechten wil uitoefenen kan je daarvoor
          een e –mail sturen naar plussstudent@ugent.be
        </p>
        <p>
          Indien je niet tevreden bent over de manier waarop je jouw
          persoonsgegevens verwerken, heb je het recht om een klacht in te
          dienen bij de Gegevensbeschermingsautoriteit.
        </p>
        <p>
          Deze Privacyverklaring kan van tijd tot tijd aangepast worden. Door
          regelmatig de website te raadplegen kan je op de hoogte blijven van
          wijzigingen.
        </p>
        <br />
        <p>
          <strong>
            Contactgegevens van de functionaris voor gegevensbescherming
          </strong>
        </p>
        <p>
          Indien je verdere vragen of opmerkingen hebt in verband met de
          verwerking van uw persoonsgegevens, kan je contact opnemen met de
          functionaris voor gegevensbescherming van UGent (privacy@ugent.be).
        </p>
        <p>
          Plusstudent is een onderwijsinnovatieproject van de Facultaire Dienst
          Onderwijsondersteuning (van de faculteit recht en criminologie van de
          Universiteit Gent) gesteund met FDO-middelen.
        </p>
        <p>&nbsp;</p>
      </div>
    </Screen>
  );
}
