import React from 'react';
import { Link } from 'react-router-dom';
import { ValidatedBadge } from '.';
import styles from './Request.module.scss';
import {Button} from '.';

export default class Request extends React.Component {
  render() {
    return (
      <article className={styles.card} onClick={this.props.articleClicked}>
        <h2>
          {this.props.data.first_name} zoekt {this.props.data.type}
        </h2>
        <p>Richting: {this.props.data.field_of_study}</p>
        {this.props.data.keywords && (
          <p>Kernwoorden: {this.props.data.keywords}</p>
        )}
        <div>
          <ValidatedBadge validated={this.props.data.validated} /> 
          {!this.props.fromRequestScreen && <Link to="/">Help {this.props.data.first_name} &gt;&gt;</Link>}
        </div>
        {this.props.fromRequestScreen && <Button clicked={this.props.removeArticle} icon="trash" label="Verwijderen"></Button>}
      </article>
    );
  }
}
