import React from 'react';
import styles from './Button.module.scss';

export default class Button extends React.Component {
  render() {
    return (
      <button
        // This beautiful syntax jizzable piece of code injects the array of extra
        // styles that are added and spreads them in the most awesome unreadable type of way
        className={`${styles.container} ${styles.blue}        
          ${[...(this.props.styles || '')]}`}
        onClick={this.props.clicked}
      >
        {this.props.icon ? <i className={"icon " + this.props.icon} /> : null}
        {this.props.label}
      </button>
    );
  }
}
