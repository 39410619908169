import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  HomeScreen,
  AboutScreen,
  DetailScreen,
  HelpScreen,
  ContactScreen,
  SuccessScreen,
  LoginScreen,
  ThanksScreen,
  AdminScreen,
  PrivacyScreen,
  RequestScreen,
  RequestValidatedScreen,
} from './screens';

export default function App() {
  return (
    <Router>
      <Switch>
        {/* A <Switch> looks through its children <Route>s and
             renders the first one that matches the current URL. */}
        <Route path="/request" component={DetailScreen} />
        <Route path="/viewrequest/:id?" component={RequestScreen}/>
        <Route path="/help" component={HelpScreen} />
        <Route path="/about" component={AboutScreen} />
        <Route path="/contact" component={ContactScreen} />
        <Route path="/success" component={SuccessScreen} />
        <Route path="/beheer" component={LoginScreen} />
        <Route path="/merci" component={ThanksScreen} />
        <Route path="/admin/requestvalidated" component={RequestValidatedScreen} />
        <Route path="/admin" component={AdminScreen} />    
        <Route path="/privacy" component={PrivacyScreen} />
        <Route path="/" component={HomeScreen} />
      </Switch>
    </Router>
  );
}
