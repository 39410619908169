import React from 'react';
import { Screen, Spinner } from '../components';
import styles from './LoadingScreen.module.scss';

export default function LoadingScreen() {
  return (
    <Screen>
      <div className={styles.container}>
        <Spinner />
      </div>
    </Screen>
  );
}
