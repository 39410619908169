import React from 'react';
import styles from './Header.module.scss';
import { Link, NavLink } from 'react-router-dom';

export default function Header() {
  return (
    <header className={styles.container}>
      <h1 className={styles.colLeft}>
        <Link className={styles.logo} to="/">
          Plusstudent
        </Link>
      </h1>
      <nav className={styles.colRight}>
        <NavLink exact to="/">
          Home
        </NavLink>
        <NavLink to="/help">Ik zoek ook hulp</NavLink>
        {/* <NavLink to="/about">Over</NavLink> */}
        <NavLink to="/contact">Contact</NavLink>
      </nav>
    </header>
  );
}
