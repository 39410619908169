import React from 'react';
import { Screen, Breadcrumb, ReplyForm, ValidatedBadge } from '../components';
import { LoadingScreen } from '../screens';
import { uploadFiles } from '../helpers';
import styles from './DetailScreen.module.scss';

export default class DetailsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,

      email: '',
      is_anonymous: false,
      field_of_study: null,
      message: '',
      gdpr_checked: false,
      files: null,
    };

    this.submitForm = this.submitForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    document.title = `${this.props.location.state.r.first_name} zoekt ${this.props.location.state.r.type}`;
  }

  validateForm() {
    this.setState({ error: null });
    if (!this.state.email || this.state.email === '') {
      this.setState({ error: 'Gelieve je e-mailadres in te vullen' });
      return false;
    }
    if (!this.state.gdpr_checked || this.state.gdpr_checked === false) {
      this.setState({ error: 'Gelieve akkoord te gaan met het privacybeleid' });
      return false;
    }
    if (
      !this.state.files &&
      (!this.state.message || this.state.message === '')
    ) {
      this.setState({
        error:
          'Gelieve ofwel een bestand up te loaden of een boodschap mee te geven',
      });
      return false;
    }
    return true;
  }

  async submitForm() {
    if (!this.validateForm()) return;

    this.setState({ loading: true });

    try {
      // Upload first
      let upload_short_id = null;
      if (this.state.files) {
        upload_short_id = await uploadFiles(this);
        if (!upload_short_id) {
          this.setState({ error: 'Kon bijlage niet uploaden', loading: false });
          return;
        } // Upload failed
      }

      const res = await fetch(
        `${global.backendRoot}/request/${this.props.location.state.r.id}/reply`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.state.email,
            is_anonymous: this.state.is_anonymous,
            message: this.state.message,
            field_of_study: this.state.field_of_study,
            first_name: this.state.first_name,
            upload_short_id,
          }),
        }
      );

      const json = await res.json();

      if (res.status === 200) {
        return this.props.history.push({
          pathname: '/merci',
          state: { helpedCount: json.helpedCount, message: json.message }, // Pass this response (helpedCount) to merci screen
        });
      } else {
        console.error(json.message);
        this.setState({ error: json.message, loading: false });
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err, loading: false });
    }
  }

  render() {
    if (this.state.loading) return <LoadingScreen />;

    const request = this.props.location.state.r;

    return (
      <Screen contentStyles={[styles.nopadding]}>
        <div className={styles.padding}>
          <Breadcrumb title={`${request.first_name} zoekt ${request.type}`} />
          <section className={styles.details}>
            <h1>
              {request.first_name} zoekt {request.type}
            </h1>

            <div>
              <p>
                <strong>Studiejaar:</strong> {request.field_of_study}
              </p>
              <p>
                <strong>Reden:</strong>&nbsp;
                {request.reason}
                <ValidatedBadge validated={request.validated} />
              </p>
              {request.reason_motivation && (
                <p>
                  <strong>Motivatie: </strong>
                  <br />
                  {request.reason_motivation}
                </p>
              )}
            </div>
          </section>
        </div>

        <ReplyForm
          request={request}
          email={this.state.email}
          emailChanged={(e) => this.setState({ email: e.target.value })}
          is_anonymous={this.state.is_anonymous}
          isAnonymousChanged={(e) =>
            this.setState({ is_anonymous: e.target.checked })
          }
          field_of_study={this.state.field_of_study}
          fieldOfStudyChanged={(e) =>
            this.setState({ field_of_study: e.target.value })
          }
          message={this.state.message}
          messageChanged={(e) => this.setState({ message: e.target.value })}
          gdpr_checked={this.state.gdpr_checked}
          gdprCheckedChanged={(e) =>
            this.setState({ gdpr_checked: e.target.checked })
          }
          fileChanged={(e) => this.setState({ files: e.target.files })}
          buttonClicked={this.submitForm}
        />

        {this.state.error && (
          <span className={styles.error}>{this.state.error}</span>
        )}

        <br />
        <br />
      </Screen>
    );
  }
}
